<template>
	<v-data-table
		v-bind="$props"
		:headers="extendedHeaders"
		:items-per-page="itemsPerPage"
		:item-class="extendedItemClass"
		dense
		:disable-sort="disableSort"
		class="cassie-table"
		no-data-text="No data available"
		fixed-header
		:height="defaultHeight"
		@current-items="$emit('current-items', $event)"
		v-on="$listeners"
	>
		<template #item.selected-column="{ item: row }">
			<v-icon :color="rowSelected(row) ? 'info' : ''">
				{{ rowSelected(row) ?
					multiSelect ? '$checkboxOn' : 'mdi-radiobox-marked' :
					multiSelect ? '$checkboxOff': 'mdi-radiobox-blank'
				}}
			</v-icon>
		</template>
		<template
			v-for="slot in slots"
			#[slot]="props"
		>
			<slot
				:name="slot"
				v-bind="props"
			/>
		</template>
	</v-data-table>
</template>

<script>
import { VDataTable } from 'vuetify/lib'

export default {
	name: 'data-table',
	extends: VDataTable,
	props: {
		selected: Function,
		multiSelect: {
			type: Boolean,
			default: false
		},
		disableSort: {
			type: Boolean,
			default: false
		},
		itemsPerPage: {
			type: Number,
			default: 15
		},
		height: {
			type: String
		}
	},
	computed: {
		hasRowSelected () {
			return !!this.selected
		},
		slots () {
			return Object.keys(this.$scopedSlots)
		},
		extendedHeaders () {
			if (!this.hasRowSelected) return this.headers
			return [
				{
					width: 35,
					value: 'selected-column',
					align: 'center'
				},
				...this.headers
			]
		},
		defaultHeight () {
			if (this.height) return this.height
			else if (this.$props.items.length >= 15 && !this.height) return '525'
			else return 'auto'
		}
	},
	methods: {
		rowSelected (row) {
			return this.hasRowSelected ? this.selected(row) : false
		},
		extendedItemClass (row) {
			const extendedClass = this.rowSelected(row) ? 'blue lighten-5' : ''
			return this.itemClass ? `${this.itemClass(row)} ${extendedClass}` : extendedClass
		}
	}
}
</script>

<style lang="scss">
.cassie-table.cassie-table {
  tr:hover:not(.v-data-table__expanded__content) {
    cursor: pointer !important;
    background-color: var(--global-main-background-color) !important;
  }
}

</style>
